/* eslint-disable no-unused-vars */
export interface ContextItem {
    id: number;
    conversation_context_id: number;
    title: string;
    content: string;
}

// Enum for schedule types
export enum ScheduleType {
    ONE_TIME = 'One Time',
    RECURRENT = 'Recurrent',
  }

// Enum for frequency
export enum Frequency {
    DAILY = 'Daily',
    WEEKLY = 'Weekly',
    NONE = 'None',
  }

export interface EmailBlastItem {
    id: number;
    email_blast_id: number;
    subject: string;
    schedule: string;
    recipients: Recipient[];
    schedule_type: string;
    start_date: string;
    frequency: string;
    start_time: string;
    day_of_week: string;
    time_zone: string;
}

export interface Recipient {
    name: string;
    email: string;
    recipient_id?: number;
}

export interface Source {
  id?: number;
  source_id?: number;
  source_type?: string;
  source_path?: string;
}

export interface UserItem {
  id: number;
  user_id: number;
  username: string;
  password: string;
  role: string;
}
