export const BASE_URL = window.location.hostname === 'localhost'
  ? 'http://localhost:3000'
  : 'https://backend.smartinsightsgen.com';

export const ChatInputStates = {
  LOADING: 0,
  TYPING: 1,
  READY: 2,
};

export const ANALYZE_SOURCES_INCLUDED = '|*|';
export const ANALYZE_SOURCES = ' [..sources]';

export const TEST_ARTICLE = `It has been about a month since the last earnings report for Novavax (NVAX). Shares have lost about 3.2% in that time frame, underperforming the S&P 500.

Will the recent negative trend continue leading up to its next earnings release, or is Novavax due for a breakout? Before we dive into how investors and analysts have reacted as of late, let's take a quick look at the most recent earnings report in order to get a better handle on the important drivers.

Q4 Earnings & Revenues Miss Estimates
Novavax reported a fourth-quarter 2023 loss of $1.44 cents per share, much wider than the Zacks Consensus Estimate of a loss of 49 cents. In the year-ago quarter, the company reported a loss of $2.28 per share.

Revenues in the quarter amounted to $291.3 million, also missing the Zacks Consensus Estimate of $334.0 million. The top line declined 18.5% on a year-over-year basis.

Quarter in Detail
In the reported quarter, the company recorded $251.4 million in product sales, reflecting a decrease of 12.6% on a year-over-year basis. The reported product sales beat our model estimates of $246.5 million.

Grant revenues declined 45.4% year over year to $37.9 million for the same period.

Novavax recorded $1.9 million of revenues from royalties and adjuvant sales to licensing partners compared with the year-ago quarter’s revenues of $0.04 million.

In the reported quarter, research and development (R&D) expenses were $165 million, down almost 36% year over year. The downside can be attributed to a reduction in clinical and manufacturing spending during the quarter.

Selling, general and administrative (SG&A) expenses declined 4.3% year over year to $155 million.

As of Dec 31, 2023, Novavax had $584 million of cash and cash equivalents compared with $666 million as of Sep 30, 2023. The company sold its common stock through at-the-market offerings, wherein it raised $110 million in net proceeds during the quarter.

Full-Year Results
In 2023, Novavax generated revenues of $983.7 million compared with $1.9 billion recorded in 2022.

In the same period, the company recorded a loss of $5.41 per share compared with a loss of $8.42 in 2022.

2024 Guidance
Novavax expects total revenues in the range of $800 million to $1 billion in 2024. The Zacks Consensus Estimate for the metric currently stands at $1.11 billion.

In the first quarter of 2024, total revenues are expected to be around $100 million.

The company expects its full-year combined R&D and SG&A expenses in the band of $700-$800 million.

How Have Estimates Been Moving Since Then?

In the past month, investors have witnessed a downward trend in estimates review.

The consensus estimate has shifted -1838.18% due to these changes.

VGM Scores

At this time, Novavax has an average Growth Score of C, though it is lagging a lot on the Momentum Score front with an F. Charting a somewhat similar path, the stock was allocated a grade of D on the value side, putting it in the bottom 40% for this investment strategy.

Overall, the stock has an aggregate VGM Score of F. If you aren't focused on one strategy, this score is the one you should be interested in.

Outlook

Estimates have been broadly trending downward for the stock, and the magnitude of these revisions indicates a downward shift. Notably, Novavax has a Zacks Rank #3 (Hold). We expect an in-line return from the stock in the next few months.

Performance of an Industry Player

Novavax is part of the Zacks Medical - Biomedical and Genetics industry. Over the past month, Myriad Genetics (MYGN), a stock from the same industry, has gained 1.9%. The company reported its results for the quarter ended December 2023 more than a month ago.

Myriad reported revenues of $196.6 million in the last reported quarter, representing a year-over-year change of +10.6%. EPS of $0.04 for the same period compares with -$0.12 a year ago.

Myriad is expected to post a loss of $0.11 per share for the current quarter, representing a year-over-year change of +47.6%. Over the last 30 days, the Zacks Consensus Estimate has changed -96.1%.

Myriad has a Zacks Rank #3 (Hold) based on the overall direction and magnitude of estimate revisions. Additionally, the stock has a VGM Score of D.`;

