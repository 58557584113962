import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../config/mainConfig';
import { useAuth } from './AuthContext';
import axios from 'axios';
import { UserItem } from '../types/ConsoleTypes';
import { GridColDef } from '@mui/x-data-grid';
import { StyledDataGrid } from '../styled_components/ManageItemsDataGrid';
import { EditRounded, DeleteRounded } from '@mui/icons-material';
import { DialogActions, FormControlLabel, Grid, IconButton, styled, Switch, TextField } from '@mui/material';
import '../styles/ManageUsers.css';
import { t } from 'i18next';

interface ManageUsersModalProps {
  onBack: () => void;
  show: boolean;
}

// eslint-disable-next-line no-unused-vars
const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#f7f7f7',
  '& .MuiInputBase-root': {
    height: '42px',
  }
}));

const useStyles = {
  dialogContent: {
    marginTop: '16px',
    minHeight: '360px', // Set minimum height
  },
  dialogActions: {
    padding: '16px'
  },
  button: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '24px'
  },
};

const StyledDialogActions = styled(DialogActions)(useStyles.dialogActions);

const ManageUsersModal: React.FC<ManageUsersModalProps> = ({ onBack, show }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [currentItem, setCurrentItem] = useState<UserItem | null>(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [content, setContent] = useState<UserItem[]>([]);
  const [passwordError, setPasswordError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const { authToken } = useAuth();

  const fetchContent = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/users`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      console.log('Fetched users content OK');
      response.data.forEach((item: UserItem) => {
        item.id = item.user_id;
      });
      setContent(response.data);
    } catch (error) {
      console.error('Error fetching content', error);
    }
  };

  useEffect(() => {
    if (show) {
      fetchContent();
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const handleDelete = async (id: number) => {
    try {
      await axios.post(`${BASE_URL}/api/users/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      await fetchContent();
    } catch (error) {
      console.error('Error deleting content', error);
    }
  };

  const handleSave = async () => {
    if (isAddMode) {
      if (!username) {
        setUsernameError('Username is required.');
        return;
      }
      if (!password) {
        setPasswordError('Password is required.');
        return;
      }
    }
    try {
      await axios.put(
        `${BASE_URL}/api/users/update/${currentItem?.id}`,
        {
          username,
          password,
          role: isAdmin ? 'admin' : 'user'
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      await fetchContent();
      handleBack();
    } catch (error) {
      console.error('Error deleting content', error);
    }
  };

  const handleAddClick = () => {
    setIsAddMode(true);
    setIsEditMode(false);
    setCurrentItem(null);
    setUsername('');
    setPassword('');
    setIsAdmin(false);
    setPasswordError('');
    setUsernameError('');
  };

  const handleEdit = (item: UserItem) => {
    setIsEditMode(true);
    setIsAddMode(false);
    setCurrentItem(item);
    setUsername(item.username);
    setPassword(item.password);
    setIsAdmin(item.role === 'admin');
    setPasswordError('');
    setUsernameError('');
  };

  const handleBack = () => {
    setIsAddMode(false);
    setIsEditMode(false);
    setPasswordError('');
    setUsernameError('');
  };

  const columns: GridColDef[] = [
    { field: 'username', headerName: t('userName'), width: 200,   flex: 1, },
    {
      field: 'edit',
      headerName: t('edit'),
      width: 100,
      renderCell: (params) => (
        <IconButton color="default" onClick={() => handleEdit(params.row as UserItem)}>
          <EditRounded />
        </IconButton>
      ),
    },
    {
      field: 'delete',
      headerName: t('delete'),
      width: 100,
      renderCell: (params) => (
        <IconButton color="default" onClick={() => handleDelete(params.row.id)}>
          <DeleteRounded />
        </IconButton>
      ),
    }
  ];

  return (
    <div className="modal-overlay">
      <div className="modal-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          {isAddMode || isEditMode ? (
            <div>
              <div className="page-header-context">
                <h4>{isEditMode ? 'Edit User' : 'Add User'}</h4>
              </div>
              <CustomTextField
                type="text"
                className="input-title"
                value={username}
                onChange={(e) => { setUsername(e.target.value); setUsernameError(''); }}
                placeholder={t('userName')}
                InputProps={{
                  readOnly: isEditMode,
                }}
                error={!!usernameError}
                helperText={usernameError}
                margin="normal"
              />
              <CustomTextField
                type="password"
                className="input-title"
                value={password}
                onChange={(e) => { setPassword(e.target.value); setPasswordError(''); }}
                placeholder={t('password')}
                error={!!passwordError}
                helperText={passwordError}
                margin="normal"
              ></CustomTextField>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.checked)}
                    name="Admin Role"
                    color="default"
                  />
                }
                label={t('isAdmin')}
              />
              <StyledDialogActions className="dialog-action">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <button className="modal-btn-users" onClick={handleBack}>{t('back')}</button>
                  </Grid>
                  <Grid item>
                    <button className="modal-btn-users" onClick={handleSave}>{t('save')}</button>
                  </Grid>
                </Grid>
              </StyledDialogActions>
            </div>
          ) : (
            <div>
              <div className="page-header-context">
                <h4>{t('userManagement')}</h4>
              </div>
              <StyledDataGrid
                rows={content}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                autoHeight
              />
              <div className="footer-users">
                <button className="modal-btn-users" onClick={onBack}>{t('back')}</button>
                <button className="modal-btn-users" onClick={handleAddClick}>{t('add')}</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageUsersModal;
