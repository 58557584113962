import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      selectSource: 'Select Source',
      analyzeSources: 'Analyze Sources',
      back: 'Back',
      addSource: 'Add Source',
      save: 'Save',
      manageContext: 'Manage Context',
      addSources: 'Add Sources',
      addSourcesOn: 'Add Sources (ON)',
      scheduleEmail: 'Schedule Email',
      manageEmails: 'Manage Emails',
      signout: 'Sign Out',
      settings: 'Instructions',
      advancedGpt: 'Insights GPT',
      password: 'Password',
      username: 'Username',
      login: 'Login',
      add: 'Add',
      contextManagement: 'Context Management',
      editContentHere: 'Edit content here...',
      title: 'Title',
      addRecipient: 'Add Recipient',
      nameOfRecipient: 'Name of recipient',
      email: 'Email',
      manageEmailBlasts: 'Manage Email Blasts',
      oneTime: 'One Time',
      recurrent: 'Recurrent',
      scheduleEmailBlast: 'Schedule Email Blast',
      recipientName: 'Recipient Name',
      recipientEmail: 'Recipient Email',
      recipients: 'Recipients',
      daily: 'Daily',
      weekly: 'Weekly',
      startTime: 'Start Time',
      timeZone: 'Time Zone',
      previewEmail: 'Preview Email',
      sendPreviewEmail: 'Send Preview Email',
      buildingPreviewEmail: 'Building and sending preview email, please wait...',
      cancel: 'Cancel',
      userComment: 'User comment',
      uploadFiles: 'Upload Files',
      submit: 'Submit',
      enterInstructionsHere: 'Enter instructions here...',
      contextTitle: 'Context Title',
      edit: 'Edit',
      select: 'Select',
      delete: 'Delete',
      typeSource: 'Type source',
      search: 'Search',
      website: 'Website',
      googleSearches: 'Searches',
      news: 'News',
      websites: 'Websites',
      subject: 'Subject',
      scheduleType: 'Schedule Type',
      date: 'Date',
      frequency: 'Frequency',
      schedule: 'Schedule',
      cancelEmail: 'Cancel Email',
      userManagement: 'User Management',
      userName: 'User Name',
      isAdmin: 'Is Admin',
      messageAdvancedGpt: 'Message Insights GPT',
      utc: 'UTC',
      ksa: 'KSA',
      dayOfWeek: 'Day of Week',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday'
    }
  },
  ar: {
    translation: {
      selectSource: 'حدد المصدر',
      analyzeSources: 'تحليل المصادر',
      back: 'رجوع',
      addSource: 'إضافة مصدر',
      save: 'حفظ',
      manageContext: 'إدارة السياق',
      addSources: 'إضافة مصادر',
      addSourcesOn: 'إضافة مصادر(ON)',
      scheduleEmail: 'جدولة البريد الإلكتروني',
      manageEmails: 'إدارة رسائل البريد الإلكتروني',
      signout: 'تسجيل الخروج',
      settings: 'تعليمات',
      advancedGpt: 'Insights GPT',
      password: 'كلمة المرور',
      username: 'اسم المستخدم',
      login: 'تسجيل الدخول',
      add: 'إضافة',
      contextManagement: 'إدارة السياق',
      editContentHere: 'تحرير المحتوى هنا...',
      title: 'العنوان',
      addRecipient: 'إضافة مستلم',
      nameOfRecipient: 'اسم المستلم',
      email: 'البريد الإلكتروني',
      manageEmailBlasts: 'إدارة رسائل البريد الإلكتروني',
      oneTime: 'مرة واحدة',
      recurrent: 'متكررة',
      scheduleEmailBlast: 'جدولة رسائل البريد الإلكتروني',
      recipientName: 'اسم المستلم',
      recipientEmail: 'بريد المستلم الإلكتروني',
      recipients: 'المستلمون',
      daily: 'يوميًا',
      weekly: 'أسبوعيًا',
      startTime: 'وقت البدء',
      timeZone: 'المنطقة الزمنية',
      previewEmail: 'معاينة البريد الإلكتروني',
      sendPreviewEmail: 'إرسال بريد إلكتروني للمعاينة',
      buildingPreviewEmail: 'إنشاء وإرسال بريد إلكتروني للمعاينة، يرجى الانتظار...',
      cancel: 'إلغاء',
      userComment: 'تعليق المستخدم',
      uploadFiles: 'تحميل الملفات',
      submit: 'إرسال',
      enterInstructionsHere: 'أدخل التعليمات هنا...',
      contextTitle: 'عنوان السياق',
      edit: 'تحرير',
      select: 'تحديد',
      delete: 'حذف',
      typeSource: 'نوع المصدر',
      search: 'بحث',
      website: 'موقع الويب',
      googleSearches: 'عمليات البحث في Google',
      news: 'أخبار',
      websites: 'مواقع الويب',
      subject: 'الموضوع',
      scheduleType: 'نوع الجدول',
      date: 'التاريخ',
      frequency: 'التكرار',
      schedule: 'الجدول',
      cancelEmail: 'إلغاء البريد الإلكتروني',
      userManagement: 'إدارة المستخدم',
      userName: 'اسم المستخدم',
      isAdmin: 'هو مسؤول',
      messageAdvancedGpt: 'ارسل لـ Insights GPT',
      utc: 'UTC توقيت',
      ksa: 'توقيت السعودية',
      dayOfWeek: 'يوم من الأسبوع',
      monday: 'الإثنين',
      tuesday: 'الثلاثاء',
      wednesday: 'الأربعاء',
      thursday: 'الخميس',
      friday: 'الجمعة',
      saturday: 'السبت',
      sunday: 'الأحد'
    }
  }
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['querystring', 'cookie'],
      cache: ['cookie']
    }
  });

export default i18n;
