import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemSecondaryAction,
  Select,
  Container,
  Box,
  ListItemText,
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import GoogleIcon from '@mui/icons-material/Google';
import UploadFile from '@mui/icons-material/UploadFile';
import EmailIcon from '@mui/icons-material/Email';
import CloudIcon from '@mui/icons-material/Cloud';
import WebIcon from '@mui/icons-material/Web';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';
import '../styles/AddSources.css';
import '../styles/UploadFiles.css';
import axios from 'axios';
import { BASE_URL } from '../config/mainConfig';
import { useAuth } from './AuthContext';
import { Source } from '../types/ConsoleTypes';
import stylesConfig from '../config/stylesConfig';
import { t } from 'i18next';

const useStyles = {
  paper: {
    padding: '16px',
    marginBottom: '16px'
  },
  listItem: {
    marginBottom: '16px'
  },
  addSource: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  header: {
    marginTop: '24px',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: '8px'
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#e0e0e0',
    marginTop: '8px',
    marginBottom: '16px'
  },
  button: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '24px',
    width: '100%'
  },
  dialogContent: {
    marginTop: '16px'
  },
  dialogActions: {
    padding: '16px'
  }
};

const ModalPaper = styled(Paper)(useStyles.paper);
const AddSourceGrid = styled(Grid)(useStyles.addSource);
const Header = styled('div')(useStyles.header);
const Icon = styled('div')(useStyles.icon);
const Divider = styled('div')(useStyles.divider);
const StyledButton = styled(Button)(useStyles.button);
const StyledDialogContent = styled(DialogContent)(useStyles.dialogContent);
const StyledDialogActions = styled(DialogActions)(useStyles.dialogActions);

const ModalListItem = styled(ListItem)({
  marginBottom: '4px',
  padding: '4px 16px',
  height: '2px',
  alignItems: 'center'
});

const StyledSelect = styled(Select)({
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '24px',
  width: '160px',
  '& .MuiSelect-select': {
    padding: '10px',
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'lightgray',
  },
});

const FileListContainer = styled(Box)({
  maxHeight: '200px',
  overflowY: 'auto',
  marginTop: '12px',
});

type SourceType = 'Search' | 'Email' | 'Cloud' | 'Website' | 'News';
// currently only Search, Website and News are supported
const sourceTypes: SourceType[] = ['Search', 'News', 'Website'];

const sourceTitles: Record<SourceType, string> = {
  Search: 'googleSearches',
  Email: 'Email Inboxes',
  Cloud: 'Cloud Folders',
  Website: 'websites',
  News: 'news',
};

interface SchedulingModalProps {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onAnalyzeSources: (message: string) => void;
}

const AddSourcesModal: React.FC<SchedulingModalProps> = ({ open, onClose, onAnalyzeSources }) => {
  const [sources, setSources] = useState<Source[]>([]);
  const [newSource, setNewSource] = useState({ type: '', path: '' });
  const [selectedSources, setSelectedSources] = useState(new Set<number>());
  const [files, setFiles] = useState<FileList | null>(null);
  const { authToken } = useAuth();

  const fetchContent = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/sources`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      console.log('Fetched content:', response.data);
      response.data.forEach((item: Source) => {
        item.id = item.source_id;
      });
      setSources(response.data);
    } catch (error) {
      console.error('Error fetching emails', error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchContent();
    }
  }, [open]);

  const handleSave = async (data: { type: string, path: string }) => {
    try {
      console.log('Adding source:', data);
      await axios.put(`${BASE_URL}/api/source/add`,
        {
          source_type: data.type,
          source_path: data.path
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      await fetchContent();
    } catch (error) {
      console.error('Error deleting content', error);
    }
  };

  const handleAddSource = () => {
    handleSave(newSource);
    if (newSource.type && newSource.path) {
      setSources([...sources, { ...newSource, id: sources.length }]);
      setNewSource({ type: '', path: '' });
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await axios.post(`${BASE_URL}/api/source/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      await fetchContent();
    } catch (error) {
      console.error('Error deleting emails', error);
    }
  };

  const handleRemoveSource = async (id: number) => {
    console.log('Removing source:', id);
    await handleDelete(id);
  };

  const handleSelectSource = (index: number) => {
    const newSelectedSources = new Set(selectedSources);
    if (newSelectedSources.has(index)) {
      newSelectedSources.delete(index);
    } else {
      newSelectedSources.add(index);
    }
    setSelectedSources(newSelectedSources);
  };

  const handleAnalyzeSources = async () => {
    const selectedSourceIds = Array.from(selectedSources);
    // concatenate selected files to selected sources
    if(files && files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      formData.append('text', 'Uploaded Files');

      try {
        const response = await axios.post(`${BASE_URL}/api/source/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`
          },
        });

        console.log('Uploaded files:', response);
        selectedSourceIds.push(response.data);
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
    onAnalyzeSources(selectedSourceIds.join(','));
    handleCloseLight();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(event.target.files);
  };

  const handleRemoveFile = (fileToRemove: File) => {
    if (files) {
      const updatedFiles = Array.from(files).filter(file => file !== fileToRemove);
      setFiles((prevFiles) => {
        if (prevFiles) {
          const dt = new DataTransfer();
          updatedFiles.forEach((file) => dt.items.add(file));
          return dt.files;
        }
        return null;
      });
    }
  };

  const handleCloseLight = () => {
    setSources([]);
    setSelectedSources(new Set());
    setNewSource({ type: '', path: '' });
    setFiles(null);
    onClose();
  };

  const handleClose = () => {
    setSources([]);
    setSelectedSources(new Set());
    setNewSource({ type: '', path: '' });
    setFiles(null);
    onAnalyzeSources('');
    onClose();
  };

  const groupedSources = sourceTypes.map(type => ({
    type,
    sources: sources.filter(source => source.source_type === type),
  }));

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <div className="page-header-context">
        <Typography variant="h6"></Typography>
      </div>
      <StyledDialogContent className='dialog-context'>
        <AddSourceGrid container spacing={2} alignItems="center">
          <Grid item xs={2.2}>
            <StyledSelect
              value={newSource.type}
              onChange={(e) => setNewSource({ ...newSource, type: e.target.value as SourceType})}
              displayEmpty
            >
              <MenuItem value="" disabled>{t('selectSource')}</MenuItem>
              {sourceTypes.map((type) => (
                <MenuItem key={type} value={type}>{t(type.toLowerCase())}</MenuItem>
              ))}
            </StyledSelect>
          </Grid>
          <Grid item xs={7.1}>
            <TextField
              fullWidth
              placeholder={t('typeSource')}
              value={newSource.path}
              onChange={(e) => setNewSource({ ...newSource, path: e.target.value })}
            />
          </Grid>
          <Grid item xs={2.2}>
            <StyledButton
              color="primary"
              variant="contained"
              onClick={handleAddSource}
              disabled={!newSource.type || !newSource.path}
            >
              {t('addSource')}
            </StyledButton>
          </Grid>
        </AddSourceGrid>
        {groupedSources.map(group => (
          <div key={group.type}>
            <Header>
              <Icon>
                {group.type === 'Search' && <GoogleIcon />}
                {group.type === 'News' && <GoogleIcon />}
                {group.type === 'Email' && <EmailIcon />}
                {group.type === 'Cloud' && <CloudIcon />}
                {group.type === 'Website' && <WebIcon />}
              </Icon>
              <Typography variant="subtitle1">{t(sourceTitles[group.type])}</Typography>
            </Header>
            <Divider />
            <List>
              {group.sources.map((source) => (
                <ModalPaper
                  key={source.id}
                  sx={{
                    backgroundColor: selectedSources.has(source.id!)
                      ? stylesConfig.selectedBackgroundColor
                      : stylesConfig.unselectedBackgroundColor,
                  }}>
                  <ModalListItem>
                    <Grid container>
                      <Grid item xs={3}>
                        <Typography variant="body2" width="400px" color="textSecondary">{source.source_path}</Typography>
                      </Grid>
                    </Grid>
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="remove" onClick={() => handleRemoveSource(source.source_id!)}>
                        <RemoveIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label={selectedSources.has(source.id!) ? 'deselect' : 'select'}
                        onClick={() => handleSelectSource(source.id!)}
                      >
                        {selectedSources.has(source.id!) ? <ClearIcon color='error' /> : <DoneIcon color='success' />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ModalListItem>
                </ModalPaper>
              ))}
            </List>
          </div>
        ))}
        {/* Added file upload section */}
        <Header>
          <Icon>
            <UploadFile />
          </Icon>
          <Typography variant="subtitle1">Upload</Typography>
        </Header>
        <Container maxWidth= 'lg'>
          <Box component="form" noValidate sx={{ mt: 0 }}>
            <input
              accept=".docx,.pdf"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button color="inherit" variant="contained" component="span" className="test" sx={{ mt: 2 }}>
                {t('uploadFiles')}
              </Button>
            </label>
            {files && files.length > 0 && (
              <FileListContainer>
                <List>
                  {Array.from(files).map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={file.name} />
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(file)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </FileListContainer>
            )}
          </Box>
        </Container>
      </StyledDialogContent>
      <StyledDialogActions className='dialog-action'>
        <Grid container justifyContent="space-between">
          <Grid item>
            <button className="modal-btn-sources short" onClick={handleClose}>{t('back')}</button>
          </Grid>
          <Grid item>
            <button className="modal-btn-sources long" onClick={handleAnalyzeSources}>{t('analyzeSources')}</button>
          </Grid>
        </Grid>
      </StyledDialogActions>
    </Dialog>
  );
};

export default AddSourcesModal;
