import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { ANALYZE_SOURCES, ANALYZE_SOURCES_INCLUDED, BASE_URL, ChatInputStates, TEST_ARTICLE } from '../config/mainConfig';
import '../styles/InsightsConsole.css';
import { useAuth } from './AuthContext';
import ManageContextModal from './ManageContextModal';
import ManageEmailsModal from './ManageEmailsModal';
import ScheduleEmailModal from './ScheduleEmailModal';
import AddSourcesModal from './AddSourcesModal';
import { formatSummary } from '../utils/common_utils';
import UserSettingsModal from './UserSettingsModal';
import UploadFilesModal from './UploadFilesModal';
import ManageUsersModal from './ManageUsersModal';
import { DeleteForeverRounded, ManageAccountsRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import { t } from 'i18next';
import i18n from '../translations/i18n';

type Actions = {
  text: string;
  tooltip: string;
  id?: string;
};

export type ChatMessage = {
  sender: 'user' | 'chatbot';
  text: string;
  actions?: Actions[];
  isHidden?: boolean;
  technicalText?: string;
};

const getPlaceholderText = (isLoading: number) => {
  switch (isLoading) {
  case ChatInputStates.LOADING:
    return 'Loading...';
  case ChatInputStates.TYPING:
    return 'Analyzing content...(might take few minutes for extensive sources)';
  default:
    return 'Message Insights GPT';
  }
};

interface InsightsConsoleProps {
  onSignOut: () => void;
  userRole?: string;
}

const InsightsConsole: React.FC<InsightsConsoleProps> = ({ onSignOut, userRole }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const chatWindowRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(ChatInputStates.READY);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const initialized = useRef(false);

  const [inputValue, setInputValue] = useState('');

  const [showContextModal, setShowContextModal] = useState(false);
  const [showManageEmailsModal, setShowManageEmailsModal] = useState(false);
  const [showScheduleEmailModal, setShowScheduleEmailModal] = useState(false);
  const [showAddSourceslModal, setShowAddSourceslModal] = useState(false);
  const [showUploadFilesModal, setShowUploadFilesModal] = useState(false);
  const [showUserSettingModal, setShowUserSettingModal] = useState(false);
  const [showManageUsersModal, setShowManageUsersModal] = useState(false);
  const [currentSources, setCurrentSources] = useState('');

  const { authToken } = useAuth();

  const toggleShowManageContextModal = () => {
    setShowContextModal(!showContextModal);
  };

  const toggleScheduleEmailModal = () => {
    setShowScheduleEmailModal(!showScheduleEmailModal);
  };

  const toggleAddSourcesModal = () => {
    setShowAddSourceslModal(!showAddSourceslModal);
  };


  const toggleShowManageEmailsModal = () => {
    setShowManageEmailsModal(!showManageEmailsModal);
  };

  const toggleUserSettingModal = () => {
    setShowUserSettingModal(!showUserSettingModal);
  };

  const goBack = () => {
    toggleScheduleEmailModal();
  };

  const goBackFromContextModal = () => {
    toggleShowManageContextModal();
  };

  const goBackFromManageEmailsModal = () => {
    toggleShowManageEmailsModal();
  };

  const toggleUploadFilesModal = () => {
    setShowUploadFilesModal(!showUploadFilesModal);
  };

  const toggleManageUsersModal = () => {
    setShowManageUsersModal(!showManageUsersModal);
  };

  const signOut = () => {
    onSignOut();
  };

  const showOptions = () => {
    toggleUserSettingModal();
  };

  const onAnalyzeSources = (sourceIds: string) => {
    if (!sourceIds) {
      setCurrentSources('');
      return;
    }
    setCurrentSources(`analyze sources{${sourceIds}}`);
  };

  const onAnalyzeUploaded = (content: string) => {
    sendMessageWithStreaming(content, true);
  };

  const sendMessage = async (userMessage: string) => {
    setMessages((messages) => [
      ...messages,
      { sender: 'user', text: userMessage },
    ]);
    const chatbotResponse = await getChatbotResponse(userMessage);
    setMessages((messages) => [
      ...messages,
      {
        sender: 'chatbot',
        text: chatbotResponse.text,
        actions: chatbotResponse.actions,
      },
    ]);
  };

  const sendMessageWithStreaming = async (userMessage: string, isHidden: boolean = false) => {
    let finalMessage = userMessage;
    const messageDisplayed = currentSources ? userMessage + ANALYZE_SOURCES : userMessage;

    setMessages((messages) => [
      ...messages,
      {
        sender: 'user',
        text: messageDisplayed,
        isHidden: isHidden,
        technicalText: userMessage + currentSources
      },
    ]);

    const updateChatbotResponse = (chunk: string) => {
      setMessages((messages) => {
        // Check the last message to determine if it's from the chatbot
        const lastMessageIndex = messages.length - 1;
        const lastMessage = messages[lastMessageIndex];

        if (lastMessage && lastMessage.sender === 'chatbot') {
          const updatedMessages = [...messages];

          // Ensure we don't duplicate lines
          const newText = lastMessage.text + chunk;
          const dedupedText = newText.split(' ')
            .filter((word, index, arr) => !index || word !== arr[index - 1])
            .join(' ');

          updatedMessages[lastMessageIndex] = {
            ...lastMessage,
            text: dedupedText,
          };
          return updatedMessages;
        } else {
          return [...messages, { sender: 'chatbot', text: chunk }];
        }
      });
    };
    if (currentSources) {
      finalMessage = userMessage + currentSources;
      setCurrentSources('');
    }
    await getChatbotResponseStreamed(finalMessage, updateChatbotResponse);
  };

  // eslint-disable-next-line no-unused-vars
  const getChatbotResponseStreamed = async (userMessage: string, updateChatbotResponse: (chunk: string) => void): Promise<void> => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const userId = queryParams.get('userId') ?? 'jan';
      setIsLoading(ChatInputStates.TYPING);

      const response = await fetch(
        `${BASE_URL}/api/usermessage/stream`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: userMessage, userId }),
        }
      );

      if (!response.body) {
        throw new Error('No response body');
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;

      while (!done) {
        const { value, done: readerDone } = await reader.read()!;
        done = readerDone;

        if (value) {
          const chunk = decoder.decode(value, { stream: !done });
          updateChatbotResponse(chunk);
        }
      }

      // formatted summary after the response is done
      setMessages((messages) => {
        const lastMessageIndex = messages.length - 1;
        const lastMessage = messages[lastMessageIndex];

        if (lastMessage && lastMessage.sender === 'chatbot') {
          const updatedMessages = [...messages];
          updatedMessages[lastMessageIndex] = {
            ...lastMessage,
            text: formatSummary(lastMessage.text),
          };
          return updatedMessages;
        }
        return messages;
      });

      setIsLoading(ChatInputStates.READY);
    } catch (error) {
      console.error('Error fetching chatbot response:', error);
      updateChatbotResponse('Sorry, there was an error processing your message.');
    }
  };

  const getChatbotResponse = async (
    userMessage: string
  ): Promise<ChatMessage> => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const userId = queryParams.get('userId') ?? 'jan';
      setIsLoading(ChatInputStates.TYPING);

      const response = await axios.post(
        `${BASE_URL}/api/usermessage`,
        { message: userMessage, userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Chatbot response:', response.data);
      setIsLoading(ChatInputStates.READY);

      return response.data;
    } catch (error) {
      console.error('Error fetching chatbot response:', error);
      return {
        sender: 'chatbot',
        text: 'Sorry, there was an error processing your message.',
      };
    }
  };

  const startNewThread = async (): Promise<void> => {
    try {
      await axios.post(
        `${BASE_URL}/api/newthread`, {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setMessages([]);
    } catch (error) {
      console.error('Error fetching chatbot response:', error);
    }
  };

  useEffect(() => {
    if (initialized.current) return; // If already initialized, do nothing
    initialized.current = true;
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('user_id') ?? 'jan';

    const fetchChatHistory = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/initialize/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }
        );
        let fetchedMessages;
        if (Array.isArray(response.data)) {
          fetchedMessages = response.data;
        } else {
          fetchedMessages = [response.data];
        }

        const formattedMessages = fetchedMessages.map((message: any) => {
          const isHidden = false;
          const analyzeSourcesIndex = message.text.indexOf(ANALYZE_SOURCES_INCLUDED);
          let technicalText = '';
          if (analyzeSourcesIndex !== -1) {
            technicalText = message.text; // Save the original text
            message.text = message.text.substring(0, analyzeSourcesIndex) + ANALYZE_SOURCES;
            console.log('message.text:', message.text);
          }
          return {
            ...message,
            isHidden,
            text: formatSummary(message.text),
            technicalText
          };
        });

        setMessages(formattedMessages);
        setIsLoading(ChatInputStates.READY);
      } catch (error) {
        console.error('Error fetching chat history:', error);
        // Handle error, maybe set some error state to show in UI
      }
    };

    fetchChatHistory();
  }, []);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (isLoading === ChatInputStates.READY) {
      (document.querySelector('.chat-input') as HTMLInputElement)?.focus();
    }
  }, [isLoading]);

  const handleActionClick = (action: string) => {
    // Implement action handling logic here
    console.log('Action clicked:', action);
  };


  // test function to start processing - currently not used
  // eslint-disable-next-line no-unused-vars
  const handleStartProcessing = async () => {
    sendMessage(TEST_ARTICLE);
  };

  const handleSelectContext = (context: string) => {
    setInputValue(prevValue => {
      const newValue = prevValue + context;
      // Adjust the textarea height
      if (textareaRef?.current) {
        const textarea = textareaRef.current as HTMLTextAreaElement;
        textarea.value = newValue;
        textarea.style.height = 'auto';  // Reset to default height to adjust to new content
        textarea.style.height = `${textarea.scrollHeight}px`;  // Set new height based on scroll height
      }
      return newValue;
    });
    toggleShowManageContextModal();
  };

  const handleInputChange = (e: { target: { value: any; }; }) => {
    const textareaLineHeight = 24;
    const { value } = e.target;

    setInputValue(value);

    // Reset textarea height to auto to calculate the new height
    if (textareaRef?.current) {
      (textareaRef.current as HTMLTextAreaElement).style.height = 'auto';
      const newHeight = Math.min( (textareaRef.current as HTMLTextAreaElement).scrollHeight, textareaLineHeight * 5) ?? textareaLineHeight;

      // Set the new height, limiting to a maximum of 5 lines
      (textareaRef.current as HTMLTextAreaElement).style.height = `${newHeight}px`;
    }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef?.current) {
      const textarea = textareaRef.current;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        // Handle Enter key without Shift
        e.preventDefault();  // Prevent default form submit action
        sendMessageWithStreaming(inputValue);
        setInputValue('');
        if (textareaRef?.current) {
          (textareaRef.current as HTMLTextAreaElement).style.height = 'auto';
        }
      } else {
        // Handle Shift + Enter (Insert a newline character)
        if (textareaRef?.current) {
          const start = textareaRef.current.selectionStart;
          const end = textareaRef.current.selectionEnd;
          const newValue = inputValue.slice(0, start) + '\n' + inputValue.slice(end);
          setInputValue(newValue);

          // Move the cursor to the right place
          setTimeout(() => {
            if (textareaRef.current) {
              textareaRef.current.selectionStart = textareaRef.current.selectionEnd = start + 1;
              adjustTextareaHeight();
            }
          }, 0);
        }
      }
    }
  };

  const addButtonClass = `email-btn${currentSources ? 'used' : ''} ${i18n.language}`;
  const addButtonText = currentSources ? t('addSourcesOn') : t('addSources');

  return (
    <div className="chatbot-container">
      <UserSettingsModal
        show={showUserSettingModal}
        onBack={toggleUserSettingModal}
      />
      <ManageContextModal
        onBack={goBackFromContextModal}
        show={showContextModal}
        onContentSelect={handleSelectContext}
      />
      <AddSourcesModal
        open={showAddSourceslModal}
        onClose={toggleAddSourcesModal}
        onAnalyzeSources={onAnalyzeSources}
      />
      <ScheduleEmailModal
        open={showScheduleEmailModal}
        onClose={goBack}
        instructions={messages.filter(m => m.sender === 'user').map(m => m.technicalText ?? m.text) }
      />
      <ManageEmailsModal
        onBack={goBackFromManageEmailsModal}
        show={showManageEmailsModal}
      />
      <UploadFilesModal
        open={showUploadFilesModal}
        onClose={toggleUploadFilesModal}
        onAnalyzeUploaded={onAnalyzeUploaded}
      />
      <ManageUsersModal
        onBack={toggleManageUsersModal}
        show={showManageUsersModal}
      />
      <div className="page-header-container">
        <div className="left-container">
          <button className="options-btn" onClick={showOptions}>{t('settings')}</button>
        </div>
        <div className="center-container">
          <h1 className="page-header">Insights GPT</h1>
        </div>
        <div className="right-container">
          <button className="sign-out-btn" onClick={signOut}>{t('signout')}</button>
        </div>
      </div>
      <div id="chat-window" ref={chatWindowRef}>
        <div id="chat-window-content">
          {messages?.filter(m => !m.isHidden).map((message, index, filteredMessages) => {
            const isLastMessage = index === filteredMessages.length - 1;
            const displayText = isLoading !== ChatInputStates.READY && isLastMessage
              ? formatSummary(message.text)
              : message.text;

            return (
              <div key={index} className={`message ${message.sender}`}>
                <div className="message-text-container">
                  <div className={`role-label ${message.sender}`}>
                    {message.sender.toUpperCase() === 'USER' ? 'USER' : 'ASSISTANT'}
                  </div>
                  {' '}
                  {/* New container */}
                  <p dangerouslySetInnerHTML={{ __html: displayText }}></p>
                  {/* Buttons container */}
                  {message.actions && (
                    <div className="message-actions">
                      {message.actions.map((action, actionIndex) => (
                        <button
                          key={actionIndex}
                          title={action.tooltip}
                          onClick={() => handleActionClick(action.text)}
                          className={`action-button ${message.sender}`}
                        >
                          {action.text}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="email-form-container">
        {/* Buttons container */}
        <div className="email-form-buttons">
          <button className={`email-btn ${i18n.language}`} onClick={toggleShowManageContextModal}>{t('manageContext')}</button>
          <button className={addButtonClass} onClick={toggleAddSourcesModal}>{addButtonText}</button>
          <button className={`email-btn ${i18n.language}`} onClick={toggleScheduleEmailModal}>{t('scheduleEmail')}</button>
          <button className={`email-btn ${i18n.language}`} onClick={toggleShowManageEmailsModal}>{t('manageEmails')}</button>
        </div>
        { isLoading !== ChatInputStates.READY ? (
          <>
            <textarea
              className="chat-input"
              id="chat-disabled"
              disabled
              placeholder={getPlaceholderText(isLoading)}
            />
          </>
        ) : (
          <textarea
            ref={textareaRef as React.RefObject<HTMLTextAreaElement>}
            className="chat-input"
            placeholder={t('messageAdvancedGpt')}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            rows={1}
          />
        )
        }
      </div>
      <div className="fab">
        <Button id="new-conversation-button" className="fab-button" onClick={startNewThread}>
          <DeleteForeverRounded className='fab-button' />
        </Button>
      </div>
      {userRole === 'admin' && (
        <div className="fabUsermanagement">
          <Button id="new-conversation-button" className="fab-button" onClick={toggleManageUsersModal}>
            <ManageAccountsRounded className='fab-button' />
          </Button>
        </div>
      )}
    </div>
  );
};

export default InsightsConsole;
