import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { BASE_URL } from '../config/mainConfig';
import { useAuth } from './AuthContext';
import '../styles/EditInstructionsModal.css';
import { t } from 'i18next';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 500px;
  padding: 16px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Textarea = styled.textarea`
  width: 95%;
  height: 200px;
  padding: 10px;
  border-radius: 8px;
  border-color: #e0e0e0;
  resize: none; /* Disable resizing */
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const ModalButton = styled.button<{ disabled: boolean }>`
  padding: 8px 16px;
  border-radius: 24px;
  width: 120px;
  border: none;
  background-color: ${props => props.disabled ? '#d3d3d3' : 'black'};
  color: ${props => props.disabled ? '#a9a9a9' : 'white'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

const Message = styled.div`
  margin-top: 2px;
  color: black;
`;

interface UserSettingsModalProps {
  onBack: () => void;
  show: boolean;
}

const UserSettingsModal: React.FC<UserSettingsModalProps> = ({ onBack, show }) => {
  const [instructions, setInstructions] = useState('');
  const [message, setMessage] = useState('');
  const { authToken } = useAuth();

  const fetchContent = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/usersettings`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      console.log('Fetched instructions OK');
      setInstructions(response.data?.instructions );
    } catch (error) {
      console.error('Error fetching content', error);
    }
  };

  useEffect(() => {
    if (show) {
      fetchContent();
    }
  }, [show]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  if (!show) {
    return null;
  }

  const handleSave = async () => {
    try {
      if (!instructions.trim()) return;
      await axios.put(
        `${BASE_URL}/api/usersettings`, { instructions },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      setMessage('Instructions saved successfully.');
    } catch (error) {
      console.error('Error deleting content', error);
    }
  };

  return (
    <ModalOverlay>
      <ModalWrapper>
        <ModalContent>
          {message && <Message>{message}</Message>}
          <Textarea
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
            placeholder={t('enterInstructionsHere')}
          />
          <ModalButtons>
            <ModalButton onClick={onBack} disabled={false}>{t('back')}</ModalButton>
            <ModalButton onClick={handleSave} disabled={!instructions.trim()}>{t('save')}</ModalButton>
          </ModalButtons>
        </ModalContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default UserSettingsModal;
