
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';

export const StyledDataGrid = styled(DataGrid)`
  width: 100%;
  border: none;
  margin-bottom: 0px;
  margin-top: 0px;

  .MuiDataGrid-root {
    border: none;
  }

  .MuiDataGrid-columnHeader {
      background-color: #f5f5f5;
  }

  .MuiDataGrid-cell {
      display: flex;
      align-items: center;
  }

  .MuiDataGrid-menuIcon {
    opacity: 1;
    color: white;
  }
`;
