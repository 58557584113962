import React, { useState } from 'react';
import InsightsConsole from './components/InsightsConsole';
import Login from './components/Login';
import { useAuth } from './components/AuthContext';

const App: React.FC = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('user');
  const { setAuthToken } = useAuth();

  const handleLoginSuccess = (userRole: string) => {
    setLoggedIn(true);
    setUserRole(userRole);
    // ready to handle user role
    console.log('setRole', userRole);
  };

  const handleSignOut = () => {
    setAuthToken(null);
    setLoggedIn(false);
    console.log('User signed out');
  };

  if (!isLoggedIn) {
    return <Login onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <div className="App">
      <header className="App-header">
      </header>
      <main>
        <InsightsConsole onSignOut={handleSignOut} userRole={userRole} />
      </main>
    </div>
  );
};

export default App;
