import { EmailBlastItem, Frequency, ScheduleType } from '../types/ConsoleTypes';

export function formatSchedule(schedule: EmailBlastItem): string {
  const { schedule_type, start_date, frequency, start_time, day_of_week } = schedule;

  if (schedule_type === ScheduleType.ONE_TIME) {
    return `${schedule_type} on ${new Date(start_date).toLocaleDateString()} at ${start_time}`;
  } else if (schedule_type === ScheduleType.RECURRENT) {
    if (frequency === Frequency.WEEKLY && day_of_week) {
      return `${schedule_type} ${frequency} on ${day_of_week} at ${start_time}`;
    } else {
      return `${schedule_type} ${frequency} at ${start_time}`;
    }
  }
  return '';
}

export function getRoundedHalfHour(): string {
  const now = new Date();
  const minutes = now.getMinutes();
  const roundedMinutes = minutes < 30 ? 30 : 0;
  if (roundedMinutes === 0) {
    now.setHours(now.getHours() + 1);
  }
  now.setMinutes(roundedMinutes);
  now.setSeconds(0);

  // Format the time as HH:MM string
  return now.toTimeString().substring(0, 5);
}

export function formatSummary(summary: string): string {
  // Replace headers
  summary = summary.replace(/###\s(.*)/g, '<h3>$1</h3>');
  summary = summary.replace(/####\s(.*)/g, '<h4>$1</h4>');

  // Replace bold text
  summary = summary.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Replace unordered lists
  summary = summary.replace(/-\s(.*)/g, '<li>$1</li>');
  summary = summary.replace(/(li>[^]*?<\/li>)((?=<h)|$)/g, '<ul>$1</ul>');

  // Replace numbered lists
  summary = summary.replace(/\d+\.\s\*\*(.*?)\*\*/g, '<li><strong>$1</strong>');
  summary = summary.replace(/<li><(strong>[^<]*<\/strong>[^<]*<\/li>)((?=<h)|$)/g, '<ol>$1</ol>');

  // Add horizontal rules
  summary = summary.replace(/---/g, '<hr>');

  return summary;
}

