import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../config/mainConfig';
import { useAuth } from './AuthContext';
import axios from 'axios';
import { EmailBlastItem, Recipient } from '../types/ConsoleTypes';
import { GridColDef } from '@mui/x-data-grid';
import { StyledDataGrid } from '../styled_components/ManageItemsDataGrid';
import { formatSchedule } from '../utils/common_utils';
import { Box, IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import { CreateRounded, CancelRounded, DeleteRounded } from '@mui/icons-material';
import '../styles/ManageEmails.css';
import { t } from 'i18next';

interface ManageEmailsModalProps {
  onBack: () => void;
  show: boolean;
}

const ModalWrapperEmail = styled.div<{ isAddMode: boolean }>`
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 90%; /* Use a percentage width for responsiveness */
    z-index: 1001; /* Higher than overlay to be on top */
    max-width: ${(props) => (props.isAddMode ? '600px' : '1200px')};
`;

const ManageEmailsModal: React.FC<ManageEmailsModalProps> = ({ onBack, show }) => {
  const [isAddMode, setIsAddMode] = useState(false);
  const [currentItem, setCurrentItem] = useState<EmailBlastItem | null>(null);
  const [inputName, setInputName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [content, setContent] = useState<EmailBlastItem[]>([]);
  const { authToken } = useAuth();

  const fetchContent = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/emails`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      console.log('Fetched content:', response.data);
      response.data.forEach((item: EmailBlastItem) => {
        item.id = item.email_blast_id as number;
        item.schedule = formatSchedule(item);
      });
      setContent(response.data);
    } catch (error) {
      console.error('Error fetching emails', error);
    }
  };

  useEffect(() => {
    if (show) {
      console.log('Fetching content...');
      fetchContent();
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const handleDelete = async (id: number) => {
    try {
      await axios.post(`${BASE_URL}/api/email/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      await fetchContent();
    } catch (error) {
      console.error('Error deleting emails', error);
    }
  };

  const handleSaveRecipient = async () => {
    try {
      await axios.put(
        `${BASE_URL}/api/recipient/add/${currentItem?.email_blast_id}`,
        {
          name: inputName,
          email: inputEmail
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      await fetchContent();
      handleBack();
    } catch (error) {
      console.error('Error adding recipient', error);
    }
  };

  const deleteRecipient = async (id: string) => {
    try {
      await axios.post(
        `${BASE_URL}/api/recipient/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      await fetchContent();
    } catch (error) {
      console.error('Error deleting recipient', error);
    }
  };

  const handleAddRecipient = (item: EmailBlastItem) => {
    setIsAddMode(true);
    setCurrentItem(item);
  };

  const handleBack = () => {
    setIsAddMode(false);
  };

  interface RecipientsCellProps {
    recipients: Recipient[];
    // eslint-disable-next-line no-unused-vars
    onDelete: (id: number) => void;
  }

  const RecipientsCell: React.FC<RecipientsCellProps> = ({ recipients, onDelete }) => (
    <Box sx={{ fontSize: 'small', height: 50, overflow: 'auto' }}>
      {recipients.map((recipient) => (
        <Box key={recipient.recipient_id} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2">{recipient.name} ({recipient.email})</Typography>
          <IconButton
            size="small"
            sx={{ ml: 1 }}
            onClick={() => onDelete(recipient.recipient_id as number)}
          >
            <DeleteRounded fontSize="small" />
          </IconButton>
        </Box>
      ))}
    </Box>
  );

  const columns: GridColDef[] = [
    { field: 'subject', headerName: t('subject'), width: 160, flex: 0.8 },
    { field: 'schedule', headerName: t('schedule'), width: 280, flex: 1 },
    {
      field: 'recipients',
      headerName: t('recipients'),
      width: 320,
      flex: 1,
      renderCell: (params) => (
        <RecipientsCell
          recipients={params.value}
          onDelete={(id: any) => deleteRecipient(id)}
        />
      ),
    },
    {
      field: 'edit',
      headerName: t('addRecipient'),
      width: 120,
      renderCell: (params) => (
        <IconButton color="default" onClick={() => handleAddRecipient(params.row as EmailBlastItem)}>
          <CreateRounded />
        </IconButton>
      ),
    },
    {
      field: 'delete',
      headerName: t('cancelEmail'),
      width: 120,
      renderCell: (params) => (
        <IconButton color="default" onClick={() => handleDelete(params.row.id)}>
          <CancelRounded />
        </IconButton>
      ),
    }
  ];

  return (
    <div className="modal-overlay">
      <ModalWrapperEmail isAddMode={isAddMode} onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          {isAddMode ? (
            <div>
              <h4 className="add-recipients">{t('addRecipient')}</h4>
              <input
                type="text"
                className="input-title"
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
                placeholder={t('nameOfRecipient')}
              />
              <input
                type="text"
                className="input-title"
                value={inputEmail}
                onChange={(e) => setInputEmail(e.target.value)}
                placeholder={t('email')}
              ></input>
              <div className="modal-buttons-email">
                <button className="modal-btn-email" onClick={handleBack}>{t('back')}</button>
                <button className="modal-btn-email" onClick={() => handleSaveRecipient()}>{t('save')}</button>
              </div>
            </div>
          ) : (
            <div>
              <div className="page-header-email">
                <h4>{t('manageEmailBlasts')}</h4>
              </div>
              <StyledDataGrid
                rows={content}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                autoHeight
              />
              <div className="back-button-container">
                <button className="modal-btn-email" onClick={onBack}>{t('back')}</button>
              </div>
            </div>
          )}
        </div>
      </ModalWrapperEmail>
    </div>
  );
};

export default ManageEmailsModal;
