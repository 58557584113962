import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton
} from '@mui/material';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { BASE_URL } from '../config/mainConfig';
import '../styles/UploadFiles.css';
import { useAuth } from './AuthContext';
import { t } from 'i18next';

const useStyles = {
  dialogContent: {
    marginTop: '16px',
    minHeight: '360px', // Set minimum height
  },
  dialogActions: {
    padding: '16px'
  }
};

const FileListContainer = styled(Box)({
  maxHeight: '200px',
  overflowY: 'auto',
  marginTop: '12px',
});

const BlackTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
});

const StyledDialogContent = styled(DialogContent)(useStyles.dialogContent);
const StyledDialogActions = styled(DialogActions)(useStyles.dialogActions);

interface UploadFilesModalProps {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onAnalyzeUploaded: (message: string) => void;
}

const UploadFilesModal: React.FC<UploadFilesModalProps> = ({ open, onClose, onAnalyzeUploaded }) => {
  const [files, setFiles] = useState<FileList | null>(null);
  const [text, setText] = useState('');
  const { authToken } = useAuth();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.files);
    setFiles(event.target.files);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleRemoveFile = (fileToRemove: File) => {
    if (files) {
      const updatedFiles = Array.from(files).filter(file => file !== fileToRemove);
      setFiles((prevFiles) => {
        if (prevFiles) {
          const dt = new DataTransfer();
          updatedFiles.forEach((file) => dt.items.add(file));
          return dt.files;
        }
        return null;
      });
    }
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (!files) {
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('text', text);

    try {
      const response = await axios.post(`${BASE_URL}/api/source/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`
        },
      });

      console.log(response.data);
      onAnalyzeUploaded('\n\n' + response.data);
      handleClose();
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const handleClose = () => {
    setFiles(null);
    setText('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <StyledDialogContent>
        <Container maxWidth= 'lg'>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 0 }}>
            <BlackTextField
              label={t('userComment')}
              variant="outlined"
              fullWidth
              multiline
              rows={2} // Set textarea with 2 lines
              value={text}
              onChange={handleTextChange}
              margin="normal"
            />
            <input
              accept=".docx,.pdf"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button color="inherit" variant="contained" component="span" className="test" sx={{ mt: 2 }}>
                {t('uploadFiles')}
              </Button>
            </label>
            {files && files.length > 0 && (
              <FileListContainer>
                <List>
                  {Array.from(files).map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={file.name} />
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(file)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </FileListContainer>
            )}
          </Box>
        </Container>
      </StyledDialogContent>
      <StyledDialogActions className="dialog-action">
        <Grid container justifyContent="space-between">
          <Grid item>
            <button className="modal-btn-upload" onClick={handleSubmit}>{t('submit')}</button>
          </Grid>
          <Grid item>
            <button className="modal-btn-upload .long" onClick={handleClose}>{t('back')}</button>
          </Grid>
        </Grid>
      </StyledDialogActions>
    </Dialog>
  );
};

export default UploadFilesModal;
