import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../config/mainConfig';
import { useAuth } from './AuthContext';
import axios from 'axios';
import { ContextItem } from '../types/ConsoleTypes';
import { GridColDef } from '@mui/x-data-grid';
import { StyledDataGrid } from '../styled_components/ManageItemsDataGrid';
import { EditRounded, DeleteRounded, ContentCopyRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import '../styles/ManageContext.css';
import { t } from 'i18next';

interface ManageContextModalProps {
  onBack: () => void;
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  onContentSelect: (content: string) => void;
}
const ManageContextModal: React.FC<ManageContextModalProps> = ({ onBack, show, onContentSelect }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [currentItem, setCurrentItem] = useState<ContextItem | null>(null);
  const [inputTitle, setInputTitle] = useState('');
  const [inputContent, setInputContent] = useState('');
  const [content, setContent] = useState<ContextItem[]>([]);
  const { authToken } = useAuth();

  const fetchContent = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/contexts`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      console.log('Fetched content:', response.data);
      response.data.forEach((item: ContextItem) => {
        item.id = item.conversation_context_id;
      });
      setContent(response.data);
    } catch (error) {
      console.error('Error fetching content', error);
    }
  };

  useEffect(() => {
    if (show) {
      fetchContent();
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const handleDelete = async (id: number) => {
    try {
      await axios.post(`${BASE_URL}/api/context/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      await fetchContent();
    } catch (error) {
      console.error('Error deleting content', error);
    }
  };

  const handleSave = async () => {
    try {
      await axios.put(
        `${BASE_URL}/api/context/update/${currentItem?.id}`,
        {
          title: inputTitle,
          content: inputContent
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      await fetchContent();
      handleBack();
    } catch (error) {
      console.error('Error deleting content', error);
    }
  };

  const handleSelect = (content: string) => {
    onContentSelect(content);
  };

  const handleAddClick = () => {
    setIsAddMode(true);
    setIsEditMode(false);
    setCurrentItem(null);
    setInputTitle('');
    setInputContent('');
  };

  const handleEdit = (item: ContextItem) => {
    setIsEditMode(true);
    setIsAddMode(false);
    setCurrentItem(item);
    setInputTitle(item.title);
    setInputContent(item.content);
  };

  const handleBack = () => {
    setIsAddMode(false);
    setIsEditMode(false);
  };

  const columns: GridColDef[] = [
    { field: 'title', headerName: t('contextTitle'), width: 320, flex: 1, },
    {
      field: 'select',
      headerName: t('select'),
      width: 100,
      renderCell: (params) => (
        <IconButton color="default" onClick={() => handleSelect(params.row.content)}>
          <ContentCopyRounded />
        </IconButton>
      ),
    },
    {
      field: 'edit',
      headerName: t('edit'),
      width: 100,
      renderCell: (params) => (
        <IconButton color="default" onClick={() => handleEdit(params.row as ContextItem)}>
          <EditRounded />
        </IconButton>
      ),
    },
    {
      field: 'delete',
      headerName: t('delete'),
      width: 100,
      renderCell: (params) => (
        <IconButton color="default" onClick={() => handleDelete(params.row.id)}>
          <DeleteRounded />
        </IconButton>
      ),
    }
  ];

  return (
    <div className="modal-overlay">
      <div className="modal-wrapper-ctx" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          {isAddMode || isEditMode ? (
            <div>
              <input
                type="text"
                className="input-title"
                value={inputTitle}
                onChange={(e) => setInputTitle(e.target.value)}
                placeholder={t('title')}
              />
              <textarea
                className="input-content"
                value={inputContent}
                onChange={(e) => setInputContent(e.target.value)}
                placeholder={t('editContentHere')}
              ></textarea>
              <div className="modal-buttons-ctx">
                <button className="modal-btn-ctx" onClick={handleBack}>{t('back')}</button>
                <button className="modal-btn-ctx" onClick={() => handleSave()}>{t('save')}</button>
              </div>
            </div>
          ) : (
            <div>
              <div className="page-header-context">
                <h4>{t('contextManagement')}</h4>
              </div>
              <StyledDataGrid
                rows={content}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                autoHeight
              />
              <div className="footer-ctx">
                <button className="modal-btn-ctx" onClick={onBack}>{t('back')}</button>
                <button className="modal-btn-ctx" onClick={handleAddClick}>{t('add')}</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageContextModal;
