import React, { useState } from 'react';
import {
  Dialog, DialogContent, DialogActions, TextField, Button, IconButton, MenuItem, Box, Typography,
  Grid,
  styled,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { ScheduleType, Frequency, Recipient } from '../types/ConsoleTypes';
import axios from 'axios';
import { BASE_URL } from '../config/mainConfig';
import { useAuth } from './AuthContext';
import { getRoundedHalfHour } from '../utils/common_utils';
import '../styles/ScheduleEmailModal.css';
import { t } from 'i18next';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const timeZones = ['UTC', 'KSA'];

interface SchedulingModalProps {
  open: boolean;
  onClose: () => void;
  instructions?: string[];
}

const useStyles = {
  dialogActions: {
    padding: '16px'
  }
};

const StyledDialogActions = styled(DialogActions)(useStyles.dialogActions);

const ScheduleEmailModal: React.FC<SchedulingModalProps> = ({ open, onClose, instructions }) => {
  const [subject, setSubject] = useState('');
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [currentRecipient, setCurrentRecipient] = useState({ name: '', email: '' });
  const [scheduleType, setScheduleType] = useState<ScheduleType>(ScheduleType.ONE_TIME);
  const [date, setDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [frequency, setFrequency] = useState<Frequency | null>(null);
  const [startTime, setStartTime] = useState<string>(getRoundedHalfHour());
  const [dayOfWeek, setDayOfWeek] = useState<string | null>(null);
  const [timeZone, setTimeZone] = useState<string>('UTC');
  const [previewEmail, setPreviewEmail] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const { authToken } = useAuth();

  const handleAddRecipient = () => {
    if (currentRecipient.name && currentRecipient.email) {
      setRecipients([...recipients, currentRecipient]);
      setCurrentRecipient({ name: '', email: '' });
    }
  };

  const handleRemoveRecipient = (index: number) => {
    const newRecipients = recipients.filter((_, i) => i !== index);
    setRecipients(newRecipients);
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};

    if (!subject) newErrors.subject = 'Subject is required';
    if (!date) newErrors.date = 'Date is required';
    if (!startTime) newErrors.startTime = 'Start time is required';
    if (!timeZone) newErrors.timeZone = 'Time zone is required';
    if (recipients.length === 0) newErrors.recipients = 'At least one recipient is required';

    if (scheduleType === ScheduleType.RECURRENT) {
      if (!frequency) newErrors.frequency = 'Frequency is required';
      if (frequency === Frequency.WEEKLY && !dayOfWeek) newErrors.dayOfWeek = 'Day of the week is required for weekly frequency';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const resetFields = () => {
    setSubject('');
    setRecipients([]);
    setCurrentRecipient({ name: '', email: ''});
    setScheduleType(ScheduleType.ONE_TIME);
    setDate(new Date().toISOString().split('T')[0]);
    setFrequency(null);
    setStartTime(getRoundedHalfHour());
    setDayOfWeek(null);
    setTimeZone('UTC');
    setErrors({});
  };

  const sendPrewiewEmail = async () => {
    if (previewEmail == '' || previewEmail == null) return;
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/api/emailpreview`,
        { subject, previewEmail, instructions },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      console.log('Email blast saved:', response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error saving email blast', error);
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    if (!validateFields()) return;
    try {
      const startDate = new Date(date + 'T' + startTime + ':00Z');

      const blast = ({
        subject,
        recipients,
        instructions,
        schedule_type: scheduleType,
        start_date: startDate,
        frequency: frequency || Frequency.NONE,
        start_time: startTime,
        day_of_week: dayOfWeek,
        time_zone: timeZone,
        created_by: 'jan'
      });

      console.log('Saving email blast:', blast);

      const response = await axios.put(`${BASE_URL}/api/emails`,
        { blast },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      console.log('Email blast saved:', response.data);
    } catch (error) {
      console.error('Error saving email blast', error);
    }
    resetFields();
    onClose();
  };

  const handleClose = () => {
    resetFields();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <div className="page-header-schedule">
        <h4>{t('scheduleEmailBlast')}</h4>
      </div>
      <DialogContent className='dialog-context'>
        <TextField
          label={t('subject')}
          fullWidth
          margin="normal"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          error={!!errors.subject}
          helperText={errors.subject}
        />
        <Box display="flex" alignItems="center" margin="normal">
          <TextField
            label={t('recipientName')}
            value={currentRecipient.name}
            onChange={(e) => setCurrentRecipient({ ...currentRecipient, name: e.target.value })}
            margin="normal"
            fullWidth
          />
          <TextField
            label={t('recipientEmail')}
            value={currentRecipient.email}
            onChange={(e) => setCurrentRecipient({ ...currentRecipient, email: e.target.value })}
            margin="normal"
            fullWidth
          />
          <Button onClick={handleAddRecipient} color="primary" aria-label="add recipient">
            <AddIcon />
          </Button>
        </Box>
        <Box marginY={2}>
          <h4>{t('recipients')}</h4>
          {errors.recipients && <Typography color="error">{errors.recipients}</Typography>}
          {recipients.map((recipient, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Typography>{recipient.name} ({recipient.email})</Typography>
              <IconButton onClick={() => handleRemoveRecipient(index)} size="small" aria-label="delete recipient">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
        <TextField
          label={t('scheduleType')}
          select
          fullWidth
          margin="normal"
          value={scheduleType}
          onChange={(e) => setScheduleType(e.target.value as ScheduleType)}
        >
          <MenuItem value={ScheduleType.ONE_TIME}>{t('oneTime')}</MenuItem>
          <MenuItem value={ScheduleType.RECURRENT}>{t('recurrent')}</MenuItem>
        </TextField>
        <TextField
          label={t('date')}
          type="date"
          fullWidth
          margin="normal"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{ shrink: true }} // Ensures the label doesn't overlap with the input
          error={!!errors.date}
          helperText={errors.date}
        />
        {scheduleType === ScheduleType.RECURRENT && (
          <>
            <TextField
              label={t('frequency')}
              select
              fullWidth
              margin="normal"
              value={frequency}
              onChange={(e) => setFrequency(e.target.value as Frequency)}
              error={!!errors.frequency}
              helperText={errors.frequency}
            >
              <MenuItem value={Frequency.DAILY}>{t('daily')}</MenuItem>
              <MenuItem value={Frequency.WEEKLY}>{t('weekly')}</MenuItem>
            </TextField>
            {frequency === Frequency.WEEKLY && (
              <TextField
                label={t('dayOfWeek')}
                select
                fullWidth
                margin="normal"
                value={dayOfWeek}
                onChange={(e) => setDayOfWeek(e.target.value)}
                error={!!errors.dayOfWeek}
                helperText={errors.dayOfWeek}
              >
                {daysOfWeek.map((day) => (
                  <MenuItem key={day} value={day}>{t(day.toLocaleLowerCase())}</MenuItem>
                ))}
              </TextField>
            )}
          </>
        )}
        <TextField
          label={t('startTime')}
          type="time"
          fullWidth
          margin="normal"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          InputLabelProps={{ shrink: true }} // Ensures the label doesn't overlap with the input
          error={!!errors.startTime}
          helperText={errors.startTime}
        />
        <TextField
          label={t('timeZone')}
          select
          fullWidth
          margin="normal"
          value={timeZone}
          onChange={(e) => setTimeZone(e.target.value)}
          error={!!errors.timeZone}
          helperText={errors.timeZone}
        >
          {timeZones.map((zone) => (
            <MenuItem key={zone} value={zone}>{t(zone.toLocaleLowerCase())}</MenuItem>
          ))}
        </TextField>
        <Box display="flex" alignItems="center" margin="normal">
          <TextField
            label={t('previewEmail')}
            fullWidth
            margin="normal"
            value={previewEmail}
            onChange={(e) => setPreviewEmail(e.target.value)}
            error={!!errors.previewEmail}
            helperText={errors.previewEmail}
          />
          <Button onClick={sendPrewiewEmail} color="inherit" variant="outlined" style={{ marginLeft: 8 }}>
            {t('sendPreviewEmail')}
          </Button>
        </Box>
        {isLoading && (
          <Box display="flex" justifyContent="center" margin="normal">
            <Typography>{t('buildingPreviewEmail')}</Typography>
          </Box>
        )}
      </DialogContent>
      <StyledDialogActions className='dialog-action'>
        <Grid container justifyContent="space-between">
          <Grid item>
            <button className="modal-btn-schedule" onClick={handleClose}>{t('back')}</button>
          </Grid>
          <Grid item>
            <button className="modal-btn-schedule" onClick={handleSave} color="primary">{t('save')}</button>
          </Grid>
        </Grid>
      </StyledDialogActions>
    </Dialog>
  );
};

export default ScheduleEmailModal;
