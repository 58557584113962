import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config/mainConfig';
import { useAuth } from './AuthContext';
import '../styles/Login.css';
import { useTranslation } from 'react-i18next';
import { Button, styled, TextField } from '@mui/material';

// eslint-disable-next-line no-unused-vars
const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#f0f0f0',
  '& .MuiInputBase-root': {
    height: '42px',
  }
}));

// eslint-disable-next-line no-unused-vars
const StyledButton = styled(Button)(({ theme }) => ({
  height: '42px',
  backgroundColor: 'lightgray',
  '&:hover': {
    backgroundColor: 'darkgray',
  },
}));

// eslint-disable-next-line no-unused-vars
function Login({ onLoginSuccess }: { onLoginSuccess: (role: string) => void }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [logoLoaded, setLogoLoaded] = useState(false);
  const { setAuthToken } = useAuth();
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  async function handleLogin() {
    try {
      const response = await axios.post(`${BASE_URL}/login`, { username, password });
      if (response.status === 200) {
        setAuthToken(response.data.token); // Store the JWT securely
        onLoginSuccess(response.data.role);
      } else {
        console.error('Login failed', response);
        alert('Login failed');
      }
    } catch (error: any) {
      console.error('There was an error!', error);
      alert(error.response?.data?.message || 'Login failed');
    }
  }

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await handleLogin();
    }
  };

  // Preload logo image
  useEffect(() => {
    const img = new Image();
    img.src = '/images/applogo2.jpeg';
    img.onload = () => setLogoLoaded(true);
  }, []);

  return (
    <div className='loginContainer'>
      <div className='logoContainer'>
        <img src="/images/applogo2.jpeg" alt="Logo" className={`logoImage ${logoLoaded ? 'loaded' : ''}`} />
      </div>
      <div className='loginForm'>
        <CustomTextField
          type="text"
          placeholder={t('username')}
          value={username}
          color='primary'
          onChange={(e) => setUsername(e.target.value)}
          className='input'
        />
        <CustomTextField
          type="password"
          placeholder={t('password')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          className='input'
          margin="normal"
        />
        <div className="languageSwitchContainer">
          <StyledButton
            onClick={() => handleLanguageChange('en')}
            className={`languageButton ${i18n.language === 'en' ? 'selected' : ''}`}
          >
            English
          </StyledButton>
          <StyledButton
            onClick={() => handleLanguageChange('ar')}
            className={`languageButton ${i18n.language === 'ar' ? 'selected' : ''}`}
          >
            العربية
          </StyledButton>
        </div>
        <button onClick={async () => { await handleLogin(); }} className='loginButton'>
          {t('login')}
        </button>
      </div>
    </div>
  );
}

export default Login;
