import React, { createContext, useContext, useState, type ReactNode } from 'react';

interface AuthContextType {
  authToken: string | null;
  // eslint-disable-next-line no-unused-vars
  setAuthToken: (token: string | null) => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

// Define the AuthProvider with a strongly-typed children prop
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authToken, setAuthToken] = useState<string | null>(null);

  // AuthContext.Provider value now conforms to AuthContextType
  const value = { authToken, setAuthToken };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
